<template>
  <section class="bg-darkGrey">
    <div class="container lg:mt-32">
      <div class="hidden lg:block">
        <div class="grid grid-cols-12 py-9">
          <div class="col-start-1 col-end-4">
            <ul class="grid gap-4 text-[14px]">
              <li>
                {{ t("Коммерческий банк Амра-банк") }}<br />
                {{ t("Общество с ограниченной ответственностью") }} <br />
                {{ t("ИНН 11000725 КПП 111000213") }} <br />{{
                  t("БИК 224100023 ОГРН 111РА000677")
                }}
              </li>
              <li>
                {{ t("384900, Республика Абхазия") }},<br />
                {{ t("Г. Сухум, ул. Лакоба-Конфедератов, 70/27") }}
              </li>
              <li>
                {{ t("Тел.: +7 840 227-73-35") }}<br />
                {{ t("WhatsApp/Telegram: +7(940) 727-73-35") }}<br />
                info@amra-bank.com
              </li>
              <li>
                {{
                  t(
                    "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г."
                  )
                }}
              </li>
            </ul>
          </div>
          <div class="flex col-start-5 col-end-10">
            <div class="grid justify-between">
              <div class="flex gap-24">
                <div class="">
                  <p class="font-medium pb-3">{{ t("Частным лицам") }}</p>
                  <ul class="text-[14px] grid gap-2">
                    <nuxt-link :to="localePath('/deposits')">{{
                      t("Вклады")
                    }}</nuxt-link>
                    <nuxt-link :to="localePath('/cards')">{{
                      t("Карты")
                    }}</nuxt-link>
                    <nuxt-link :to="localePath('/safeboxes')">{{
                      t("Сейфовые ячейки")
                    }}</nuxt-link>
                    <nuxt-link :to="localePath('/coins')">{{
                      t("Монеты и банкноты")
                    }}</nuxt-link>
                    <a :href="tariff" target="_blank">{{ t("Тарифы физических лиц") }}</a>
                  </ul>
                </div>
                <div class="">
                  <p class="font-medium pb-3">{{ t("Бизнесу") }}</p>
                  <ul class="text-[14px] grid gap-2">
                    <nuxt-link :to="localePath('/business#business')">{{
                      t("Кредитование")
                    }}</nuxt-link>
                    <nuxt-link :to="localePath('/business#acquirer')">{{
                      t("Эквайринг")
                    }}</nuxt-link>
                    <nuxt-link :to="localePath('/business#payroll')">{{
                      t("Зарплатный проект")
                    }}</nuxt-link>
                    <nuxt-link :to="localePath('/')">{{
                      t("Онлайн-банк")
                    }}</nuxt-link>
                    <a :href="tariffLegal" target="_blank">{{ t("Тарифы юридических лиц") }}</a>
                  </ul>
                </div>
              </div>
              <div>
                <p class="font-medium">{{ t('Скачивайте приложение') }}</p>
                <div class="flex justify-between mt-4">
                  <a href="https://www.rustore.ru/catalog/app/com.bifit.mobile.private.amra">
                    <a-icon-google24></a-icon-google24></a>
                  <a href="https://apps.apple.com/ru/app/%D0%B0%D0%BC%D1%80%D0%B0/id6737840702  ">
                    <a-icon-apple24></a-icon-apple24></a>
                  <a href="http://minio.amra-bank.com/apk/mobile-private-amra-64.5.0-31427.apk">
                    <a-icon-android24></a-icon-android24>
                  </a>
                </div>
              </div>
              <div class="flex gap-8 font-medium text-[14px] items-end">
                <nuxt-link :to="localePath('/about')">{{
                  t("О банке")
                }}</nuxt-link>
                <nuxt-link :to="localePath('/news')">{{
                  t("Новости и статьи")
                }}</nuxt-link>
                <nuxt-link :to="localePath('/offices')">{{
                  t("Офисы и банкоматы")
                }}</nuxt-link>
                <nuxt-link :to="localePath('/vacancies')">{{
                  t("Вакансии")
                }}</nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-start-10 col-end-13">
            <div class="grid justify-end">
              <div class="grid gap-5 justify-end">
                <div class="flex justify-end"><a-icon-logo></a-icon-logo></div>
                <div class="flex gap-5 justify-end">
                  <a href="https://www.instagram.com/amrabank/" target="_blank">
                    <a-icon-instagram> </a-icon-instagram>
                  </a>
                  <a href="https://www.facebook.com/amrabank/" target="_blank">
                    <a-icon-facebook></a-icon-facebook>
                  </a>
                  <a href="https://www.youtube.com/channel/UCnIk21nX8_E9bI5h8hm_X4w/videos?view=0&amp;sort=da"
                    target="_blank">
                    <a-icon-youtube></a-icon-youtube>
                  </a>
                </div>
                <div class="flex mt-6 items-center gap-3">
                  <p class="text-sm w-38 text-end">
                    {{
                      t("Отсканируйте QR-код и запустите свой бот амра-кошелёк")
                    }}
                  </p><a-icon-qr></a-icon-qr>
                </div>
                <a :href="agreement" target="_blank" class="font-medium text-[14px] text-end pt-1">{{
                  t("Пользовательское соглашение") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-[10px] lg:hidden block pt-[10px]">
        <div class="grid gap-[10px] justify-center">
          <a-icon-logo></a-icon-logo>
          <div class="flex gap-5 justify-center">
            <a href="https://www.instagram.com/amrabank/" target="_blank">
              <a-icon-instagram> </a-icon-instagram>
            </a>
            <a href="https://www.facebook.com/amrabank/" target="_blank">
              <a-icon-facebook></a-icon-facebook>
            </a>
            <a href="https://www.youtube.com/channel/UCnIk21nX8_E9bI5h8hm_X4w/videos?view=0&amp;sort=da"
              target="_blank">
              <a-icon-youtube></a-icon-youtube>
            </a>
          </div>
        </div>
        <div class="flex justify-between gap-5 mt-5 px-4">
          <div class="">
            <p class="font-medium mb-[10px]">{{ t("Частным лицам") }}</p>
            <ul class="text-[14px] grid gap-2">
              <nuxt-link :to="localePath('/deposits')">{{
                t("Вклады")
              }}</nuxt-link>
              <nuxt-link :to="localePath('/cards')">{{ t("Карты") }}</nuxt-link>
              <nuxt-link :to="localePath('/safeboxes')">{{
                t("Сейфовые ячейки")
              }}</nuxt-link>
              <nuxt-link :to="localePath('/coins')">{{
                t("Монеты и банкноты")
              }}</nuxt-link>
            </ul>
          </div>
          <div class="">
            <p class="font-medium pb-[10px]">{{ t("Бизнесу") }}</p>
            <ul class="text-[14px] grid gap-2">
              <nuxt-link :to="localePath('/business#business')">{{
                t("Кредитование")
              }}</nuxt-link>
              <nuxt-link :to="localePath('/business#acquirer')">{{
                t("Эквайринг")
              }}</nuxt-link>
              <nuxt-link :to="localePath('/business#payroll')">{{
                t("Зарплатный проект")
              }}</nuxt-link>
              <a :href="tariff" target="_blank">{{ t("Тарифы физических лиц") }}</a>
            </ul>
          </div>


        </div>
        <div class="px-4 pt-4">
          <div class="grid grid-cols-1 justify-center font-medium gap-2">
            <div class="flex gap-3 justify-center">
              <nuxt-link :to="localePath('/about')">{{ t("О банке") }}</nuxt-link>
             <nuxt-link :to="localePath('/offices')">{{
              t("Офисы и банкоматы")
                }}</nuxt-link>
              <nuxt-link :to="localePath('/news')">{{
                t("Новости и статьи")
              }}</nuxt-link>
            </div>
            <div class="flex gap-3 justify-center">   <nuxt-link :to="localePath('/vacancies')">{{
                t("Вакансии")
              }}</nuxt-link>
             
              <a :href="agreement" target="_blank" class="">{{
                t("Пользовательское соглашение") }}</a>
            </div>


          </div>
        </div>

        <div class="px-4 pt-4">
          <p class="font-medium">{{ t('Скачивайте приложение') }}</p>
          <div class="flex justify-between mt-3">
            <a href="https://www.rustore.ru/catalog/app/com.bifit.mobile.private.amra">
              <a-icon-google24></a-icon-google24></a>
            <a href="https://apps.apple.com/ru/app/%D0%B0%D0%BC%D1%80%D0%B0/id6737840702  ">
              <a-icon-apple24></a-icon-apple24></a>
            <a href="http://minio.amra-bank.com/apk/mobile-private-amra-64.5.0-31427.apk">
              <a-icon-android24></a-icon-android24>
            </a>
          </div>
        </div>
        <div class="flex px-4 pt-4">
          <ul class="grid gap-4 text-[14px]">
            <li>
              {{ t("Коммерческий банк Амра-банк") }}<br />
              {{ t("Общество с ограниченной ответственностью") }}<br />
              {{ t("ИНН 11000725 КПП 111000213") }} <br />
              {{ t("БИК 224100023 ОГРН 111РА000677") }}
            </li>
            <li>
              {{ t("384900, Республика Абхазия") }},<br />
              {{ t("Г. Сухум, ул. Лакоба-Конфедератов, 70/27") }}
            </li>
            <li>
              {{ t("Тел.: +7 840 227-73-35") }}<br />
              {{ t("WhatsApp/Telegram: +7(940) 727-73-35") }}<br />
              info@amra-bank.com
            </li>
            <li>
              {{
                t(
                  "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г."
                )
              }}
            </li>
          </ul>
        </div>
        <div class="flex justify-center mt-5">
          <component :is="qr"></component>
        </div>
        <p class="text-sm text-center mt-[10px]">
          {{ t("Отсканируйте QR-код и запустите свой бот амра-кошелёк") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import qr from "@/components/a-icon/a-icon-qr.vue";
const { t } = useI18n({ useScope: "local" });
import tariff from "../assets/pdf/tariff.pdf"
import tariffLegal from "../assets/pdf/tariff-legal.pdf"
import agreement from "../assets/pdf/user_agreement.pdf"
</script>
<i18n lang="json">{
  "ru": {
    "Коммерческий банк Амра-банк": "Коммерческий банк «Амра-банк»",
    "Общество с ограниченной ответственностью": "(Общество с ограниченной ответственностью)",
    "ИНН 11000725 КПП 111000213": "ИНН 11000725 КПП 111000213",
    "БИК 224100023 ОГРН 111РА000677": "БИК 224100023 ОГРН 111РА000677",
    "Тел.: +7 840 227-73-35": "Тел.: +7 840 227-73-35",
    "384900, Республика Абхазия": "384900, Республика Абхазия",
    " Г. Сухум, ул. Лакоба-Конфедератов, 70/27": " Г. Сухум, ул. Лакоба-Конфедератов, 70/27",
    " WhatsApp/Telegram: +7(940) 727-73-35": " WhatsApp/Telegram: +7(940) 727-73-35",
    "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г.": "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г.",
    "Частным лицам": "Частным лицам",
    "Вклады": "Вклады",
    "Карты": "Карты",
    "Сейфовые ячейки": "Сейфовые ячейки",
    "Монеты и банкноты": "Монеты и банкноты",
    "Бизнесу": "Бизнесу",
    "Кредитование": "Кредитование",
    "Эквайринг": "Эквайринг",
    "Зарплатный проект": "Зарплатный проект",
    "О банке": "О банке",
    "Новости и статьи": "Новости и статьи",
    "Офисы и банкоматы": "Офисы и банкоматы",
    "Вакансии": "Вакансии",
    "Отсканируйте QR-код и запустите свой бот амра-кошелёк": "Отсканируйте QR-код и запустите свой бот амра-кошелёк",
    "Скачивайте приложение": "Скачивайте приложение"
  },
  "en": {
    "Коммерческий банк Амра-банк": "Commercial bank «Amra-bank»",
    "Общество с ограниченной ответственностью": "(Limited Liability Company)",
    "ИНН 11000725 КПП 111000213": "INN 11000725 KPP 111000213",
    "БИК 224100023 ОГРН 111РА000677": "BIC 224100023 OGRN 111RA000677",
    "Тел.: +7 840 227-73-35": "Tel.: +7 840 227-73-35",
    "384900, Республика Абхазия": "384900, Republic of Abkhazia",
    " Г. Сухум, ул. Лакоба-Конфедератов, 70/27": " G. Sukhumi, Lakoba-Konfederatov St., 70/27",
    " WhatsApp/Telegram: +7(940) 727-73-35": " WhatsApp/Telegram: +7(940) 727-73-35",
    "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г.": "Gen. license for banking operations No. 023 / 02.07.2008",
    "Частным лицам": "For Individuals",
    "Вклады": "Deposits",
    "Карты": "Cards",
    "Сейфовые ячейки": "Safe Deposit Boxes",
    "Монеты и банкноты": "Coins and Banknotes",
    "Бизнесу": "For Businesses",
    "Кредитование": "Lending",
    "Эквайринг": "Acquiring",
    "Зарплатный проект": "Payroll Project",
    "О банке": "About the Bank",
    "Новости и статьи": "News and Articles",
    "Офисы и банкоматы": "Offices and ATMs",
    "Вакансии": "Vacancies",
    "Отсканируйте QR-код и запустите свой бот амра-кошелёк": "Scan the QR code to launch your Amra-wallet bot"
  },
  "es": {
    "Коммерческий банк Амра-банк": "Banco comercial «Amra-bank»",
    "Общество с ограниченной ответственностью": "(Sociedad de Responsabilidad Limitada)",
    "ИНН 11000725 КПП 111000213": "NIF 11000725 KPP 111000213",
    "БИК 224100023 ОГРН 111РА000677": "BIC 224100023 OGRN 111RA000677",
    "Тел.: +7 840 227-73-35": "Tel.: +7 840 227-73-35",
    "384900, Республика Абхазия": "384900, República de Abjasia",
    " Г. Сухум, ул. Лакоба-Конфедератов, 70/27": " G. Sujumi, C. Lakoba-Konfederatov, 70/27",
    " WhatsApp/Telegram: +7(940) 727-73-35": " WhatsApp/Telegram: +7(940) 727-73-35",
    "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г.": "Licencia gen. para operaciones bancarias No. 023 / 02.07.2008",
    "Частным лицам": "Para Individuos",
    "Вклады": "Depósitos",
    "Карты": "Tarjetas",
    "Сейфовые ячейки": "Cajas de Seguridad",
    "Монеты и банкноты": "Monedas y Billetes",
    "Бизнесу": "Para Negocios",
    "Кредитование": "Préstamos",
    "Эквайринг": "Adquirencia",
    "Зарплатный проект": "Proyecto de Nómina",
    "О банке": "Sobre el Banco",
    "Новости и статьи": "Noticias y Artículos",
    "Офисы и банкоматы": "Oficinas y Cajeros Automáticos",
    "Вакансии": "Vacantes",
    "Отсканируйте QR-код и запустите свой бот амра-кошелёк": "Escanee el código QR para lanzar su bot de Amra-wallet"
  },
  "ar": {
    "Коммерческий банк Амра-банк": "البنك التجاري ' أمرا بنك '",
    "Общество с ограниченной ответственностью": "(شركة ذات مسؤولية محدودة)",
    "ИНН 11000725 КПП 111000213": "الرقم الضريبي 11000725 الرمز البريدي 111000213",
    "БИК 224100023 ОГРН 111РА000677": "رمز البنك 224100023 الرقم التنظيمي 111РА000677",
    "Тел.: +7 840 227-73-35": "الهاتف: +7 840 227-73-35",
    "384900, Республика Абхазия": "384900، جمهورية أبخازيا",
    "Г. Сухум, ул. Лакоба-Конфедератов, 70/27": " G. سوخومي، شارع لاكوبا-كونفدراتوف، 70/27",
    " WhatsApp/Telegram: +7(940) 727-73-35": " WhatsApp/Telegram: +7(940) 727-73-35",
    "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г.": "الرخصة العامة للعمليات المصرفية رقم 023 / 02.07.2008",
    "Частным лицам": "للأفراد",
    "Вклады": "الودائع",
    "Карты": "البطاقات",
    "Сейфовые ячейки": "صناديق الأمانات",
    "Монеты и банкноты": "العملات والأوراق النقدية",
    "Бизнесу": "للأعمال",
    "Кредитование": "القروض",
    "Эквайринг": "الاستحواذ",
    "Зарплатный проект": "مشروع الرواتب",
    "О банке": "عن البنك",
    "Новости и статьи": "الأخبار والمقالات",
    "Офисы и банкоматы": "المكاتب وأجهزة الصراف الآلي",
    "Вакансии": "الوظائف الشاغرة",
    "Отсканируйте QR-код и запустите свой бот амра-кошелёк": "امسح رمز الاستجابة السريعة لتشغيل روبوت محفظة أمرا"
  },
  "tr": {
    "Коммерческий банк Амра-банк": "Ticaret bankası «Amra-bank»",
    "Общество с ограниченной ответственностью": "(Limited Şirket)",
    "ИНН 11000725 КПП 111000213": "Vergi Kimlik Numarası 11000725 Kayıtlı Posta Kodu 111000213",
    "БИК 224100023 ОГРН 111РА000677": "Banka Kodu 224100023 Kurum Kodu 111РА000677",
    "Тел.: +7 840 227-73-35": "Tel.: +7 840 227-73-35",
    "384900, Республика Абхазия": "384900, Abhazya Cumhuriyeti",
    " Г. Сухум, ул. Лакоба-Конфедератов, 70/27": " G. Suhumi, Lakoba-Konfederatov Cad., 70/27",
    " WhatsApp/Telegram: +7(940) 727-73-35": " WhatsApp/Telegram: +7(940) 727-73-35",
    "Ген. лицензия на осуществление банковских операций №023 / 02.07.2008 г.": "Genel Bankacılık Lisansı No. 023 / 02.07.2008",
    "Частным лицам": "Bireyler İçin",
    "Вклады": "Mevduatlar",
    "Карты": "Kartlar",
    "Сейфовые ячейки": "Kiralık Kasalar",
    "Монеты и банкноты": "Madeni Paralar ve Banknotlar",
    "Бизнесу": "İşletmeler İçin",
    "Кредитование": "Kredi",
    "Эквайринг": "Satış Noktası Hizmetleri",
    "Зарплатный проект": "Maaş Projesi",
    "О банке": "Banka Hakkında",
    "Новости и статьи": "Haberler ve Makaleler",
    "Офисы и банкоматы": "Ofisler ve ATM'ler",
    "Вакансии": "İş İlanları",
    "Отсканируйте QR-код и запустите свой бот амра-кошелёк": "QR kodunu tarayarak Amra-cüzdan botunuzu başlatın"
  }
}</i18n>
